import * as React from "react"
import Layout from "../components/layout"
import StoreInfo from "../components/storeInfo"
import StoreRelated from "../components/storeRelated"
import Gallery from "../components/gallery"
import Spacer from "../components/spacer"
import Seo from "../components/seo"
import { graphql } from 'gatsby'

const StorePage = ({data}) => {
  var others = [];
  var related =  data.related.edges;
  related.forEach(store => {
    if(store.node.category.category ===data.store.category.category) {
      others.push(store.node);
    }
  })
  others.sort(() => Math.random() - 0.5)
  return (
  <Layout>
    <div className='flex m-wrap'>
      <div className='w-50 m-100'>
        <StoreInfo data={data} />
      </div>
      <div className='w-50 m-100'>
        <Gallery images={data.store.gallery} />
      </div>
    </div>
    {( others.length > 0 ?
      <StoreRelated related={others.slice(0,2)} /> : ''
    )}
    <Spacer />
  </Layout>
  )
} 


export const Head = ({data}) => <Seo title={data.store.name} />

export default StorePage


export const query = graphql`
  query PostQuery($id: String!) {
    store:datoCmsStore(id: { eq: $id }) {
      name
      phone
      address
      introduction
      instagram
      website
      nonHttps
      category {
        category
      }
      gallery {
        gatsbyImageData
      }
      tradingHours {
        title
        tradingHour
      }
    }
    related:allDatoCmsStore(filter: {id: {ne: $id}}) {
      edges {
        node {
          slug
          name
          category{
            category
          }
          gallery {
            gatsbyImageData
          }
        }
      }
    }
  }
`
