import * as React from "react"
const StoreHours = ({hours}) => {
  const toggleHours = () => {
    var toggle = document.querySelector('.toggle-hours');
    if(toggle.classList.contains('active')) {
      toggle.classList.remove('active')
      document.querySelector('.hours--wrapper').style.height = '0px';
    } else {
      toggle.classList.add('active')
      document.querySelector('.hours--wrapper').style.height =  document.querySelector('.hours-inner').offsetHeight+'px';
    }
  }
  return (
    <div className='toggle-hours' onClick={toggleHours} role='presentation'>
    <p className='small uppercase m0 bb1 pb10 hours-title'>Trading Hours</p>
    <div className='hours hours--wrapper'>
      <div className='hours-inner pt10'>
      {hours?.map((hours, index) => {
        return ( 
          <div className='mt5 flex' key={index}>
            <span>{hours.title}</span>
            <span className='mla'>{hours.tradingHour}</span>
          </div>
        )
      })}
      </div>
    </div>
    </div>
  )
}

export default StoreHours
