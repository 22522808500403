import React, { useEffect } from 'react';
import StoreHours from "../components/storeHours"

const StoreInfo = ({data}) => {
  useEffect(() => {
    if(window.innerWidth > 768) {
    setInterval(function () {
        if(window.pageYOffset > 200) {
          document.querySelector('.business--details')?.classList.add('active')
        } else {
          document.querySelector('.business--details')?.classList.remove('active')
        }
    }, 300);
    }
  },[])
  return (
  <div className='store--information sticky'>
    <div className='mh-100vh m-mha flex flex-wrap'>
      <div className='w-100 p20'>
        <div className='w-70 m-100 text-center mt40'>
          <p className='m0 uppercase small'>({data.store.category.category})</p>
          <p className='m0 h1 mt20 m-mb40'>{data.store.name}</p>
          <div className='white-space text-left mt20'> 
          {data.store.introduction}
          </div>
        </div>
      </div>
      <div className='w-100 p20 mta flex m-wrap m-col-reverse align-bottom'>
        <div className='w-70 m-100 m-mb40'>
          {(data.store.tradingHours.length > 0 ? 
          <div className='max-275 m-mwa'>
            <StoreHours hours={data.store.tradingHours}/>
          </div> : ''
          )}
        </div>
        <div className='w-30 m-100 m-mb40'>
          <div className='max-250 m-mwa mla f-small business--details'>
            <div className='white-space'>
              {data.store.address}
            </div> 
            <a className='link ul-link' href={'tel:'+data.store.phone}>{data.store.phone}</a>
            {(data.store.website ? 
            <div className='mt20'>
              <p className='m0 small grey mb5 uppercase'>(website)</p>
              <a className='link ul-link' target='_blank' rel='noreferrer' href={(data.store.nonHttps ? 'http://' : 'https://' )+data.store.website}>{data.store.website}</a>
            </div> : ''
            )}
            {(data.store.instagram ? 
            <div className='mt20'>
              <p className='m0 small grey mb5 uppercase'>(social)</p>
              ig. <a className='link ul-link' target='_blank' rel='noreferrer' href={'https://www.instagram.com/'+data.store.instagram}>{data.store.instagram}</a>
            </div> : ''
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default StoreInfo
