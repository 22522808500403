import * as React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from "gatsby"

const EventRelated = ({related}) => {
  return (
  <div className='other--events w-50 mt40 m-100'>
    <p className='m0 pl20'>Related Brands</p>
    <div className='p20 flex w-50 m-66'>
      {related?.map((store, index) => {
        return ( 
          <Link to={'/store/'+store.slug} className='w-50 pr10 link' key={index}>
            <div className='ratio-1-2 bg-grey pos-rel mb5'>
              <GatsbyImage className='bg-image' image={store.gallery[0]?.gatsbyImageData} alt='Store Thumbnail' />
            </div>
            <p className='aime uppercase m0'>{store.name}</p>
          </Link>
        )
      })}
    </div>
  </div>
  )
}

export default EventRelated
